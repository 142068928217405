.boxbg,.fullDialog{
  min-height: 100vh;
  background: #eceff1;
  background: linear-gradient(15deg, rgb(224,224,224) 0%, rrgb(233, 237, 254)100%)  ;
}

.loginMainContainer {
  min-height: 100vh;
  background: #eceff1;
  background: linear-gradient(15deg, rgb(224,224,224) 0%, rrgb(233, 237, 254)100%)  ;
  padding-top:9rem ;
}

.app_style{
  background-color:rgb(225, 225, 250);
}

::-webkit-scrollbar {
  width: 5px;
  overflow-y: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

